<template>
  <router-link
    :to="{
      name: 'Set',
      params: {
        set: set.item_url_str.set,
        details: set.item_url_str.details,
      },
    }"
  >
    <img
      :src="set.image_url_str"
      :alt="set.set_id + ' ' + set.metal"
      class="mx-auto"
    />
    <div v-if="showText || showPrice || showSetId" class="px-8">
      <div class="flex" v-if="showText">
        <p class="flex-1 text-metal">{{ set.metal }}</p>
        <p v-if="set.stone.size" class="flex-1 text-right text-stone">
          {{ $n(set.stone.size, "carat") }} ct. {{ set.stone.quality }}
        </p>
      </div>

      <div v-if="showSetId || showPrice" class="flex">
        <p class="flex-1 text-setid">
          <span v-if="showSetId">{{ set.set_id }}</span>
        </p>
        <p class="flex-1 text-right text-price font-bold">
          <span v-if="showPrice">{{ $n(set.price, "currency") }}</span>
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "CatalogSet",
  props: ["set"],
  setup() {
    /**
     * catalog config
     */

    const showPrice =
      typeof window.rubinCatalog.catalog.price === "boolean"
        ? window.rubinCatalog.catalog.price
        : true;
    const showSetId =
      typeof window.rubinCatalog.catalog.setId === "boolean"
        ? window.rubinCatalog.catalog.setId
        : true;
    const showText =
      typeof window.rubinCatalog.catalog.text === "boolean"
        ? window.rubinCatalog.catalog.text
        : true;

    return { showText, showPrice, showSetId };
  },
};
</script>
