<template>
  <div id="rubin-catalog" class="relative bg-white w-full py-8">
    <div v-if="catalogSets.length > 0" class="max-w-screen-xl mx-auto">
      <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        <template v-for="(set, setIdx) in catalogSets" :key="setIdx">
          <li
            v-if="typeof teaserBoxes[setIdx] === 'object'"
            :key="'teaser' + setIdx"
            :class="teaserBoxes[setIdx].class"
            v-html="teaserBoxes[setIdx].node"
          >

          </li>

          <li class="p-4 hover:shadow-md rounded-md">
            <catalog-set :set="set" />
          </li>
        </template>
      </ul>
      <div v-if="itemsLeft > 0" class="flex my-4">
        <div class="mx-auto">
          <button
            class="
              more-button
              text-sm
              cursor-pointer
              border border-brand-main
              text-brand-main
              uppercase
              tracking-widest
              bg-white
              hover:bg-brand-main hover:text-white
              px-10
              py-2
            "
            @click="showMore"
          >
            {{ $t("catalog.showMore.button", { items: itemsLeft }) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { Catalog } from "@/assets/ts/catalog";
import { useStore } from "vuex";
import CatalogSet from "@/components/catalog/set";

export default {
  name: "Catalog",
  components: { CatalogSet },
  setup() {
    /**
     * catalog config
     */

    const itemsInBlock = 9;
    const rawCatalogSets = ref([]);
    const currentItems = ref(itemsInBlock);

    let catalogId = ref(0);
    let showAll = ref(false);
    let moreCatalogs = ref(false);

    /**
     * catalog init
     */

    const init = () => {
      if (typeof window.rubinCatalog !== "undefined") {
        catalogId.value =
          typeof window.rubinCatalog.catalog.id === "number"
            ? window.rubinCatalog.catalog.id
            : 27;
        showAll.value =
          typeof window.rubinCatalog.catalog.showAll === "boolean"
            ? window.rubinCatalog.catalog.showAll
            : false;

        moreCatalogs.value =
          typeof window.rubinCatalog.catalog.moreCatalogs === "boolean"
            ? window.rubinCatalog.catalog.moreCatalogs
            : false;

        loadCatalog();
      }
    };

    const teaserBoxes = computed(() => {
      let teasers = {};

      if (typeof window.rubinCatalog.catalog.teaserBoxes === "object") {
        let rawTeaserBoxes =
          typeof window.rubinCatalog.catalog.teaserBoxes === "object"
            ? window.rubinCatalog.catalog.teaserBoxes
            : [];
        for (let idx in rawTeaserBoxes) {
          console.log(typeof rawTeaserBoxes[idx].pos);
          if (typeof rawTeaserBoxes[idx].pos !== "undefined") {
            const rows = rawTeaserBoxes[idx].rows > 1 ? "row-span-" + rawTeaserBoxes[idx].rows: ""
            const cols = rawTeaserBoxes[idx].cols > 1 ? "md:col-span-" + rawTeaserBoxes[idx].cols: ""

            teasers[rawTeaserBoxes[idx].pos] = {
              class: rows + " " + cols,
              node: document.getElementById(rawTeaserBoxes[idx].nodeId).innerHTML,
            };
          }
        }
      }

      return teasers;
    });

    const loadCatalog = () => {
      /**
       * Gets the items from the catalog API
       */
      const CatalogObj = new Catalog();
      const store = useStore();

      store.commit("loading/actionStarted");
      CatalogObj.get(catalogId.value).then((data) => {
        rawCatalogSets.value = data;
        store.commit("loading/actionFinished");
      });
    };

    init();

    /**
     * Extract the items that should be shown.
     */

    const catalogSets = computed(() => {
      if (showAll.value) {
        return rawCatalogSets.value;
      } else {
        return rawCatalogSets.value.slice(0, currentItems.value);
      }
    });

    /**
     * Adds more items to be shown.
     */
    const showMore = () => {
      currentItems.value += itemsInBlock;
    };

    /**
     * Number of items that are not yet shown
     */
    const itemsLeft = computed(() => {
      return rawCatalogSets.value.length - currentItems.value;
    });

    return {
      catalogId,
      catalogSets,
      showMore,
      itemsLeft,
      moreCatalogs,
      teaserBoxes,
    };
  },
};
</script>
