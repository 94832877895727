export class Set {
  get(set: string, details: string) {
    return new Promise((resolve, reject) => {
      const detailsSplit = details.split("-");
      fetch(
        process.env.VUE_APP_API_HOST +
          "/set/" +
          set.toLowerCase() +
          "/" +
          detailsSplit[0]
            .substring(0, detailsSplit[0].length - 1)
            .toLowerCase() +
          "/" +
          detailsSplit[0]
            .substring(detailsSplit[0].length - 1, detailsSplit[0].length)
            .toLowerCase() +
          "/" +
          detailsSplit[1] +
          "/" +
          detailsSplit[2],
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status == 500) {
            reject(new Error("Unknown set"));
          }
          return response.json();
        })
        .then((data) => {
          if (typeof data == "object") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch(() => {
          reject(new Error("Unknown set"));
        });
    });
  }
}
