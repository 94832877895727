export class Catalog {
  get(catalogId: number) {
    return new Promise((resolve, reject) => {
      fetch(process.env.VUE_APP_API_HOST + "/catalog/" + catalogId, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status == 500) {
            reject(new Error("Unknown catalog"));
          }
          return response.json();
        })
        .then((data) => {
          if (typeof data == "object") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch(() => {
          reject(new Error("Unknown catalog"));
        });
    });
  }

  getList() {
    return new Promise((resolve, reject) => {
      fetch(process.env.VUE_APP_API_HOST + "/catalogs", {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status == 500) {
            reject(new Error("Error"));
          }
          return response.json();
        })
        .then((data) => {
          if (typeof data == "object") {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch(() => {
          reject(new Error("Error"));
        });
    });
  }
}
